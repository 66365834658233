import { GridCol, Notification } from "@lmig/lmds-react";

type NotificationProps = {
  displayed: boolean;
  alert: string;
  message: string;
};
const InfoNotification = (props: NotificationProps) => {
  const { displayed, alert, message } = props;
  if (!displayed) {
    return null;
  }
  return (
    <GridCol base={12}>
      <Notification
        highlightType="neutral"
        alert={alert}
        data-testid="InfoNotification"
        style={{ marginBottom: "10px" }}
      >
        {message}
      </Notification>
    </GridCol>
  );
};

export default InfoNotification;
