import { ConfirmationSvg } from "./assets/confirmationIllustration";
import { ConfirmationDisplayStyle } from "./confirmationDisplay.styles";

export const ConfirmationDisplay = () => {
  return (
    <ConfirmationDisplayStyle>
      <h1>{`You're all set!`}</h1>
      <ConfirmationSvg />
    </ConfirmationDisplayStyle>
  );
};
