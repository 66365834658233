import React from "react";
import "./styles.css";

const LoadingSpinner = () => (
  <div id="loading-spinner-id" className="spinner-container">
    <div className="spinner" />
  </div>
);

export default LoadingSpinner;
