import { BodyText } from "@lmig/lmds-react";
import { AddressUpdateDisplay } from "./components/AddressUpdateDisplay";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "@lmig/lmds-react";
import { ErrorComponent } from "../../components/error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";

export const RequestAddressUpdateConfirmation = () => {
  const [updateAccountCallFinished, setUpdateAccountCallFinished] =
    useState(false);
  const [updateAccountError] = useState(false);
  const {
    ADDRESS_UPDATE_FAILED_ALERT,
    ADDRESS_UPDATE_FAILED_MESSAGE,
    GENERIC_PAYMENT_ERROR,
    CONTACT_REP,
  } = STRINGS;

  useEffect(() => {
    if (!updateAccountCallFinished) {
      // TODO: add API GW call to update account status to incorrect address
      setUpdateAccountCallFinished(true);
    }
  }, [updateAccountCallFinished]);

  if (!updateAccountCallFinished) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {updateAccountError ? (
        <ErrorComponent
          title={GENERIC_PAYMENT_ERROR}
          alert={ADDRESS_UPDATE_FAILED_ALERT}
          message={`${ADDRESS_UPDATE_FAILED_MESSAGE} \n ${CONTACT_REP} \n `}
        />
      ) : (
        <>
          <AddressUpdateDisplay />
          <BodyText type="lead">
            {
              "Thank you. Your Liberty representative will be in touch to update your address."
            }
          </BodyText>
        </>
      )}
    </>
  );
};
