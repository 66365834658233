import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppProvider, {
  AppStateContext,
  getUser,
  useAppState,
} from "./client/context/AppContext";
import { theme } from "./client/theme/base-theme";
import Authentication from "./client/components/authentication/Authentication";
import {
  AccountCapture,
  Auth,
  CheckConfirmation,
  Confirmation,
  ConfirmAddress,
  RequestAddressUpdateConfirmation,
  SelectPaymentType,
  Terms,
  Verification,
} from "./client/pages";
import { PAGES } from "./client/utilities/consts";
import {
  getBrandFromUrl,
  isBrand,
} from "./client/utilities/get-brand-from-url";
import { useAppStore } from "./client/store/store";

export function HomeContainer() {
  const { state } = useAppState();
  const { user } = state;
  const {
    ACCOUNT_CAPTURE,
    UPDATE_ADDRESS_CONFIRMATION,
    CHECK_CONFIRMATION,
    CONFIRM_ADDRESS,
    DDE_CONFIRMATION,
    SELECT_PAYMENT_TYPE,
    TERMS,
    VERIFICATION,
  } = PAGES;
  const { setSelectedBrand } = useAppStore();

  const urlParams = new URLSearchParams(window.location.search);

  let loginParam = urlParams.get("login");
  if (!loginParam && user && user.loginParam) {
    loginParam = user.loginParam;
  }

  let idParam = urlParams.get("id");
  if (!idParam && user && user.idParam) {
    idParam = user.idParam;
  }

  const { dispatch } = useContext(AppStateContext);
  useEffect(() => {
    getUser(dispatch, loginParam, idParam);
  }, [dispatch, loginParam, idParam]);

  useEffect(() => {
    let brand = getBrandFromUrl(window.location);
    if (user?.brand !== undefined && user?.brand !== null) {
      isBrand(user.brand) && (brand = user.brand);
    }
    setSelectedBrand(brand);
  }, [setSelectedBrand, user?.brand]);

  return (
    <ThemeProvider theme={theme}>
      <Authentication>
        <CssBaseline />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path={`/${VERIFICATION}`} element={<Verification />} />
            <Route
              path={`/${SELECT_PAYMENT_TYPE}`}
              element={<SelectPaymentType />}
            />
            <Route path={`/${TERMS}`} element={<Terms />} />
            <Route path={`/${ACCOUNT_CAPTURE}`} element={<AccountCapture />} />
            <Route path={`/${DDE_CONFIRMATION}`} element={<Confirmation />} />
            <Route
              path={`/${CHECK_CONFIRMATION}`}
              element={<CheckConfirmation />}
            />
            <Route path={`/${CONFIRM_ADDRESS}`} element={<ConfirmAddress />} />
            <Route
              path={`/${UPDATE_ADDRESS_CONFIRMATION}`}
              element={<RequestAddressUpdateConfirmation />}
            />
          </Routes>
        </Container>
      </Authentication>
    </ThemeProvider>
  );
}

export default function App() {
  return (
    <React.Fragment>
      <AppProvider>
        <HomeContainer />
      </AppProvider>
    </React.Fragment>
  );
}
