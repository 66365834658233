import { BodyText } from "@lmig/lmds-react";
import { Address } from "../../../store/store.types";

interface DisplayAddressProps {
  payeeAddress: Address;
}

export const DisplayAddress = (props: DisplayAddressProps) => {
  const { payeeAddress } = props;
  const region =
    "state" in payeeAddress ? payeeAddress.state : payeeAddress.province;

  return (
    <>
      <BodyText type="article">{payeeAddress.line1}</BodyText>
      {!!payeeAddress.line2 ? (
        <BodyText type="article">{payeeAddress.line2}</BodyText>
      ) : null}
      <BodyText type="article">{`${payeeAddress.city}, ${region}`}</BodyText>
      <BodyText type="article">{payeeAddress.postalCode}</BodyText>
      {!!payeeAddress.country ? (
        <BodyText type="article">{payeeAddress.country}</BodyText>
      ) : null}
    </>
  );
};
