import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { apiGwService } from "../../services/api-gw-service";
import { useState, useEffect } from "react";
import { useAppState } from "../../context/AppContext";
import { ConfirmationSuccess } from "../../components/confirmation/ConfirmationSuccess";
import { ErrorComponent } from "../../components/error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";

export const Confirmation = () => {
  const [iframeSuccess, setIframeSuccess] = useState(false);
  const [iframeSubmissionError, setIframeSubmissionError] = useState(false);
  const { state } = useAppState();
  const { user } = state;
  const [enrollmentComplete, setEnrollmentComplete] = useState(false);
  const {
    DDE_CONFIRMATION_HEADING,
    DDE_CONFIRMATION_BODY,
    GENERIC_DDE_ERROR,
    ACCOUNT_DETAILS_SUBMISSION_FAILED_ALERT,
    ACCOUNT_DETAILS_SUBMISSION_FAILED_MESSAGE,
    CONTACT_REP,
  } = STRINGS;

  useEffect(() => {
    if (!iframeSuccess) {
      console.log("--- Call SUBMIT API");
      apiGwService.iframeSubmission
        .submit("submitted", user?.id)
        .then((response) => {
          if (!response.isSubmissionSuccessful || response.errorOccured) {
            setIframeSubmissionError(true);
          } else {
            setEnrollmentComplete(true);
          }
          setIframeSuccess(true);
        });
    }
  }, [iframeSuccess, user?.id]);

  if (!iframeSuccess) {
    return <LoadingSpinner />;
  }

  return (
    <span className="confirmation">
      {enrollmentComplete && (
        <ConfirmationSuccess
          heading={DDE_CONFIRMATION_HEADING}
          bodyText={DDE_CONFIRMATION_BODY}
        />
      )}
      {iframeSubmissionError && (
        <ErrorComponent
          title={GENERIC_DDE_ERROR}
          alert={ACCOUNT_DETAILS_SUBMISSION_FAILED_ALERT}
          message={`${ACCOUNT_DETAILS_SUBMISSION_FAILED_MESSAGE} \n ${CONTACT_REP} \n `}
        />
      )}
    </span>
  );
};
