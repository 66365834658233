export const PaperCheckIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.8041 34.3875H3.19587V12.4127H48.8041V34.3875ZM1.59793 9.2168H50.4021C51.2841 9.2168 52 9.93267 52 10.8147V35.9854C52 36.8707 51.2841 37.5833 50.4021 37.5833H1.59793C0.712679 37.5833 0 36.8707 0 35.9854V10.8147C0 9.93267 0.712679 9.2168 1.59793 9.2168ZM31.1847 21.6168H9.28019V18.4209H31.1847V21.6168ZM9.28019 28.2705H19.9288V25.0746H9.28019V28.2705ZM37.8923 21.66L37.8955 19.0586C37.2947 19.196 36.8345 19.7137 36.8313 20.3561C36.8313 20.7204 36.9719 21.0624 37.2308 21.3212C37.413 21.5034 37.6431 21.6025 37.8923 21.66ZM41.4911 25.4854C41.4911 25.1211 41.3505 24.7791 41.0916 24.5202C40.8743 24.3029 40.5867 24.1847 40.283 24.1495L40.2767 26.8117C40.9542 26.735 41.4879 26.1821 41.4911 25.4854ZM37.8788 26.8372L37.8852 24.0536C35.9613 23.8906 34.4304 22.315 34.4336 20.3496C34.44 18.3873 35.974 16.8182 37.9012 16.6616V15.5845L40.2981 15.5909V16.6072L42.695 16.6104L42.6886 19.0073L40.2917 19.0041L40.2853 21.7558C42.2859 21.8421 43.8902 23.472 43.887 25.4917C43.8806 27.5083 42.2699 29.1318 40.2693 29.2117L40.2661 30.7553L37.8692 30.7489L37.8724 29.2341L35.6896 29.2309L35.696 26.834L37.8788 26.8372Z"
      fill="#1A1446"
    />
  </svg>
);
