export const PAYMENT_TYPES = {
  NOT_SELECTED: "NOT_SELECTED",
  CHECK: "CHECK",
  DIRECT_DEPOSIT: "DIRECT_DEPOSIT",
} as const;

export type PaymentType = (typeof PAYMENT_TYPES)[keyof typeof PAYMENT_TYPES];

export const BRANDS = {
  LIBERTY_MUTUAL: "LibertyMutual",
  SAFECO: "Safeco",
} as const;

export type Brand = (typeof BRANDS)[keyof typeof BRANDS];

export type Address = USAddress | CANAddress;

export interface USAddress {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country?: "USA";
}
export interface CANAddress {
  line1: string;
  line2?: string;
  city: string;
  province: string;
  postalCode: string;
  country?: "CAN";
}
