export const PAGES = {
  ACCOUNT_CAPTURE: "accountCapture",
  CHECK_CONFIRMATION: "checkConfirmation",
  CONFIRM_ADDRESS: "confirmAddress",
  DDE_CONFIRMATION: "confirmation",
  UPDATE_ADDRESS_CONFIRMATION: "confirmAddressUpdate",
  SELECT_PAYMENT_TYPE: "selectPaymentType",
  TERMS: "terms",
  VERIFICATION: "Verification",
};
