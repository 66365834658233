import { GridCol, Notification } from "@lmig/lmds-react";

type ErrorProps = {
  isError: boolean;
  alert: string;
  message: string;
};
const ErrorNotification: React.FunctionComponent<ErrorProps> = (props) => {
  const { isError, alert, message } = props;
  if (!isError) {
    return null;
  }
  return (
    <GridCol base={12}>
      <Notification
        highlightType="negative"
        alert={alert}
        data-testid="errorNotification"
        style={{ marginBottom: "10px" }}
      >
        {message}
      </Notification>
    </GridCol>
  );
};

export default ErrorNotification;
