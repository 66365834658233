import { useAppStore } from "../../store/store";
import Stepper from "../../components/stepper/Stepper";
import { stepLabels } from "../../components/stepper/steps-data";
import { BodyText, RadioGroup, Radio, Button } from "@lmig/lmds-react";
import { useState } from "react";
import { DisplayAddress } from "./components/AddressDisplay";
import { IncorrectAddressOptions } from "./components/IncorrectAddressOptions";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utilities/consts";
import {
  StyledHeading,
  StyledAddressBlurb,
  StyledAddressDisplayContainer,
  StyledButtonsContainer,
} from "./ConfirmAddress.styles";

export const ConfirmAddress = () => {
  const { payeeAddress } = useAppStore();
  const navigate = useNavigate();
  const [isAddressCorrect, setIsAddressCorrect] = useState<boolean | undefined>(
    undefined,
  );
  const [showIncorrectAddressOptions, setShowIncorrectAddressOptions] =
    useState(false);
  const { CHECK_CONFIRMATION, SELECT_PAYMENT_TYPE } = PAGES;

  const handleContinue = () => {
    isAddressCorrect
      ? navigate(`/${CHECK_CONFIRMATION}`)
      : setShowIncorrectAddressOptions(true);
  };

  return (
    <>
      <Stepper activeStepLabel={stepLabels.PAYMENT_LOCATION} />
      {showIncorrectAddressOptions ? (
        <IncorrectAddressOptions />
      ) : (
        <>
          <StyledHeading type="h3-light">
            {"Please verify your mailing address"}
          </StyledHeading>

          <StyledAddressBlurb>
            <BodyText>
              {
                "The following address is where your paper check will be mailed to."
              }
            </BodyText>
            <BodyText type="default-medium">
              {
                "If the address below is not correct, please contact your claims adjuster."
              }
            </BodyText>
          </StyledAddressBlurb>

          <StyledAddressDisplayContainer>
            {!!payeeAddress ? (
              <DisplayAddress payeeAddress={payeeAddress} />
            ) : null}
          </StyledAddressDisplayContainer>

          <RadioGroup
            name={"confirmAddress"}
            onChange={(e) => setIsAddressCorrect(JSON.parse(e.target.value))}
          >
            <Radio
              labelVisual="Yes, this address is correct. Please send my check here."
              value={"true"}
            />
            <Radio
              labelVisual="No, this address is not correct."
              value={"false"}
            />
          </RadioGroup>
          <StyledButtonsContainer>
            <Button
              aria-label="Back to payment type selection"
              onClick={() => {
                navigate(`/${SELECT_PAYMENT_TYPE}`);
              }}
            >
              {"Back to payment type selection"}
            </Button>
            <Button
              type="submit"
              disabled={isAddressCorrect === undefined}
              variant="primary"
              aria-label="Continue"
              onClick={() => handleContinue()}
            >
              {"Continue"}
            </Button>
          </StyledButtonsContainer>
        </>
      )}
    </>
  );
};
