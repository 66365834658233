import styled from "styled-components";
import colorTokens from "@lmig/lmds-tokens/dist/json/core/color.json";

export const ConfirmationDisplayStyle = styled.div`
  height: 50%;
  background-color: ${colorTokens.lmig.color.teal10};
  border-radius: 10px;
  text-align: center;
  border: 2px solid ${colorTokens.lmig.color.tealMedium};
  padding: 20px;
  overflow: scroll;
`;
