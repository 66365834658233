import { useMediaQuery } from "react-responsive";
import { StepProps } from "./components/Step";
import DesktopStepper from "./components/DesktopStepper";
import MobileStepper from "./components/MobileStepper";
import breakpointTokens from "@lmig/lmds-tokens/dist/json/core/breakpoint.json";
import { useAppStore } from "../../store/store";
import { PAYMENT_TYPES } from "../../store/store.types";
import {
  checkSteps,
  ddeSteps,
  noSelectionSteps,
  StepLabel,
} from "./steps-data";

type StepperProps = {
  activeStepLabel: StepLabel;
};

export type StepperDisplayProps = {
  activeStepNumber: number;
  steps: StepProps[];
};

const Stepper = (props: StepperProps) => {
  const isMobileWidth = useMediaQuery({
    query: `(max-width: ${breakpointTokens.lmig.breakpoint.sm.max})`,
  });
  const { selectedPaymentType, isCheckEnabled } = useAppStore();
  const { activeStepLabel } = props;

  let selectedFlowSteps: StepProps[];
  if (isCheckEnabled) {
    selectedFlowSteps =
      selectedPaymentType === PAYMENT_TYPES.CHECK ? checkSteps : ddeSteps;
  } else {
    selectedFlowSteps = noSelectionSteps;
  }
  const activeStepNumber =
    selectedFlowSteps.find((step) => step.text === activeStepLabel)?.order || 0;

  return isMobileWidth ? (
    <MobileStepper
      activeStepNumber={activeStepNumber}
      steps={selectedFlowSteps}
    />
  ) : (
    <DesktopStepper
      activeStepNumber={activeStepNumber}
      steps={selectedFlowSteps}
    />
  );
};

export default Stepper;
