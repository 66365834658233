import { StyledCardsContainer } from "./IncorrectAddressOptions.styles";
import { OptionCard } from "./OptionCard";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utilities/consts";

export const IncorrectAddressOptions = () => {
  const navigate = useNavigate();
  const { UPDATE_ADDRESS_CONFIRMATION, SELECT_PAYMENT_TYPE } = PAGES;

  return (
    <StyledCardsContainer>
      <OptionCard
        heading="I'd like to update my address in order to receive a paper check."
        buttonText="Notify my Liberty representative"
        action={() => navigate(`/${UPDATE_ADDRESS_CONFIRMATION}`)}
      />
      <OptionCard
        heading="I'd like to choose Direct Deposit."
        buttonText="Go to Direct Deposit"
        action={() => navigate(`/${SELECT_PAYMENT_TYPE}`)}
      />
    </StyledCardsContainer>
  );
};
