import { StyledStepper } from "../Stepper.styles";
import Step from "./Step";
import { StepperDisplayProps } from "../Stepper";

const DesktopStepper = (props: StepperDisplayProps) => {
  const { activeStepNumber, steps } = props;

  return (
    <StyledStepper>
      {steps.map((step) => (
        <Step key={step.order} activeStep={activeStepNumber} {...step} />
      ))}
    </StyledStepper>
  );
};

export default DesktopStepper;
