type FormattedQuestion = {
  question: string;
  customerProvidedAnswer: string | null;
};

export const mapQuestionSetToApiFormat = (questionSet: {
  [x: string]: string | null;
}) => {
  const formattedQuestions: FormattedQuestion[] = [];
  Object.keys(questionSet).forEach((question) =>
    formattedQuestions.push({
      question,
      customerProvidedAnswer: questionSet[question],
    }),
  );
  return formattedQuestions;
};
