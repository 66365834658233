import { AddressDocumentSvg } from "../assets/AddressDocumentSvg";
import { StyledAddressUpdateDisplayContainer } from "./AddressUpdateDisplay.styles";
import { Heading } from "@lmig/lmds-react";

export const AddressUpdateDisplay = () => {
  return (
    <StyledAddressUpdateDisplayContainer>
      <Heading type="h2-light">{"Your address needs to be updated."}</Heading>
      <AddressDocumentSvg />
    </StyledAddressUpdateDisplayContainer>
  );
};
