import styled from "styled-components";

import colorTokens from "@lmig/lmds-tokens/dist/json/core/color.json";

export const StyledAddressUpdateDisplayContainer = styled.div`
  background-color: ${colorTokens.lmig.color.teal10};
  border-radius: 12px;
  border: 1px solid ${colorTokens.lmig.color.tealMedium};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 64px;
`;
