import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { useEffect } from "react";

export const Auth = () => {
  const navigate = useNavigate();
  console.log("*** Wait for Auth *** ");
  useEffect(() => {
    navigate("/verification");
  }, [navigate]);

  return <LoadingSpinner />;
};
