import React from "react";
import {
  Header as LMDSHeader,
  LogoLibertyMutualHoriz,
  LogoSafecoHoriz,
} from "@lmig/lmds-react";
import { useAppStore } from "../../store/store";
import { BRANDS, Brand } from "../../store/store.types";

import "./Header.scss";

const Header = () => {
  const { selectedBrand } = useAppStore();
  const { LIBERTY_MUTUAL, SAFECO } = BRANDS;

  const logos: Record<Brand, React.FunctionComponent> = {
    [LIBERTY_MUTUAL]: LogoLibertyMutualHoriz,
    [SAFECO]: LogoSafecoHoriz,
  };

  const BrandLogo = logos[selectedBrand];

  return (
    <LMDSHeader id="lmds-header">
      <BrandLogo />
    </LMDSHeader>
  );
};

export default Header;
