import { Card, Notification } from "@lmig/lmds-react";

const IFrameError = () => {
  return (
    <Card
      style={{
        marginBottom: "0",
        maxWidth: "70%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Notification alert="We encountered an error." highlightType="negative">
        Please contact your claims representative for assistance.
      </Notification>
    </Card>
  );
};

export default IFrameError;
